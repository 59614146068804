import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable, bindingMode } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { AddressService } from 'services/address-service';
import { CountryService } from 'services/country-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class AddressFormInlineEdit {
  @bindable private address: Models.Address;
  private countries: Array<any>;

  constructor(
    private addressService: AddressService,
    private countryService: CountryService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private attached() {
    this.countries = this.countryService.getCountryList();
  }

  private updateAddress() {
    this.addressService
      .put(this.address, this.address.Id)
      .then((res) => {
        this.eventAggregator.publish('addressListReset', 1);
        this.eventAggregator.publish('addressFormEditClose', 1);
        this.toastService.showSuccess('address.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteAddress() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.addressService
          .delete(this.address.Id)
          .then((res) => {
            this.eventAggregator.publish('addressFormEditClose', 1);
            this.eventAggregator.publish('addressListReset', 1);
            this.toastService.showSuccess('address.deleted');
          })
          .catch((err) => this.errorService.handleError(err));
      });
  }

  private cancelEdit() {
    this.eventAggregator.publish('addressFormEditClose', 1);
    this.eventAggregator.publish('addressListReset', 1);
  }
}
